import { useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { useQueryClient } from 'react-query';
import { localGetItem } from '../utils/handleStorage';

// svg image
import { ReactComponent as MoreLogo } from '../assets/images/more_logo.svg';
import { ReactComponent as MoreQuestion } from '../assets/images/more_question.svg';
import { ReactComponent as MoreNotice } from '../assets/images/more_notice.svg';
import { ReactComponent as MoreSell } from '../assets/images/more_sell.svg';
import { ReactComponent as MoreWatch } from '../assets/images/more_watch.svg';
import { ReactComponent as MoreBlog } from '../assets/images/more_blog.svg';
import { ReactComponent as MoreNews } from '../assets/images/more_news.svg';
import { ReactComponent as MorePrivacy } from '../assets/images/more_privacy.svg';
import { ReactComponent as MoreClub } from '../assets/images/more_club.svg';
// import { ReactComponent as MoreRecruitment } from '../assets/images/more_recruitment.svg';
import { ReactComponent as ArrowBack } from '../assets/images/ArrowBack.svg';
import { ReactComponent as MoreCoupon } from '../assets/images/icon_coupon.svg';
import { ReactComponent as SubscriptionIcon } from '../assets/images/icon_subscription.svg';
import ConfirmModal from '../Components/ConfirmModal';
import { getNotice } from '../fetcher/useQueries';
import { useUserInfo } from '../Queries/userQueries';
import FullLoading from '../Components/Loading/FullLoading';
import AppLink from '@/Components/AppLink';
import SignUpAppOnlyModal from '@/Components/Modal/SignUpAppOnlyModal';
import ConfirmAppLinkModal from '@/Components/Modal/ConfirmAppLinkModal';
import AppOnlyModal from '@/Components/Modal/AppOnlyModal';
import { IconBadgeList } from '@/assets/icons/badge';
type color = {
  color?: string;
};

const MoreContainer = styled.div`
  width: 100%;
  padding: 50px 20px;
`;

const LoginContainer = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 400;
`;

const UserLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
`;

const TokenLink = styled(Link)<color>`
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 50px;
  width: 76px;
  height: 33px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignUpTokenLink = styled.div<color>`
  cursor: pointer;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 50px;
  width: 76px;
  height: 33px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TokenButton = styled.button`
  border: 1px solid #484f61;
  color: #484f61;
  border-radius: 50px;
  width: 80px;
  height: 33px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MoreMiddleButtonWrapper = styled.div`
  padding: 26px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const MoreMiddleButtonLink = styled(Link)`
  border: 1px solid rgba(72, 79, 97, 0.2);
  border-radius: 10px;
  padding: 12px;
  height: 70px;
  width: 100%;
  position: relative;

  span {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 14px;
    color: #484f61;
  }
`;

const MoreRouteSectionNav = styled.nav`
  padding: 10px 0px 10px 0px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #484f61;
    padding-bottom: 12px;
  }

  li {
    padding: 16px 0px;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const MoreRouteSectionLink = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
`;

const MoreRouteSectionA = styled.a`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
`;

const ArrowFront = styled(ArrowBack)`
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
`;

const NoticeButton = styled.a`
  padding: 0px 16px;
  width: 100%;
  height: 40px;
  margin-top: 20px;

  background: #ff8a22;
  border-radius: 10px;

  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
  }
`;

const isRN = (window as any).isRNWebView;

const CouponRegisterButton = ({
  isAppOnlyModalOpen,
  setIsAppOnlyModalOpen,
}: {
  isAppOnlyModalOpen: boolean;
  setIsAppOnlyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleButtonClick = () => {
    setIsAppOnlyModalOpen(true);
  };

  return isRN ? (
    <AppLink to={`CouponScreen`} notItemPage>
      <MoreRouteSectionA>
        <MoreCoupon className="icon" />
        쿠폰 등록
      </MoreRouteSectionA>
    </AppLink>
  ) : (
    <>
      <MoreRouteSectionA onClick={() => handleButtonClick()}>
        <MoreCoupon className="icon" />
        쿠폰 등록
      </MoreRouteSectionA>
      {isAppOnlyModalOpen && (
        <AppOnlyModal
          featureName="쿠폰 등록"
          isModalOpen={isAppOnlyModalOpen}
          onModalClose={() => setIsAppOnlyModalOpen(false)}
        />
      )}
    </>
  );
};

const SubscriptionButton = ({
  isAppOnlyModalOpen,
  setIsAppOnlyModalOpen,
}: {
  isAppOnlyModalOpen: boolean;
  setIsAppOnlyModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleButtonClick = () => {
    setIsAppOnlyModalOpen(true);
  };

  return isRN ? (
    <AppLink to={`SubscriptionProgramScreen`} notItemPage>
      <MoreRouteSectionA>
        <SubscriptionIcon className="icon" />
        금은 정기구독
      </MoreRouteSectionA>
    </AppLink>
  ) : (
    <>
      <MoreRouteSectionA onClick={() => handleButtonClick()}>
        <SubscriptionIcon className="icon" />
        금은 정기구독
      </MoreRouteSectionA>
      {isAppOnlyModalOpen && (
        <AppOnlyModal
          featureName="금은 정기구독"
          isModalOpen={isAppOnlyModalOpen}
          onModalClose={() => setIsAppOnlyModalOpen(false)}
        />
      )}
    </>
  );
};

const BadgeListButton = () => {
  return (
    <AppLink to="/badge" title="뱃지 현황">
      <MoreRouteSectionA>
        <IconBadgeList className="icon" />
        뱃지 현황
      </MoreRouteSectionA>
    </AppLink>
  );
};

const More = ({ history }: RouteComponentProps) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const [isSignUpAppOnlyModalOpen, setIsSignUpAppOnlyModalOpen] =
    useState(false);
  const [isAppOnlyModalOpen, setIsAppOnlyModalOpen] = useState(false);
  const { data: noticeData, isLoading: isLoadingNoticeData } = getNotice({
    select: (result) => result.filter((v) => v.emphasis === 1),
  });

  const token = localGetItem('session');

  const {
    data,
    isError: userInfoError,
    isLoading: userInfoLoading,
  } = useUserInfo({
    enabled: !!token,
    retry: false,
    staleTime: 10 * 1000,
  });

  const userInfo = useMemo(() => data?.data, [data]);

  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.removeItem('session');
    localStorage.removeItem('device');
    queryClient.invalidateQueries();
    history.push('/');
    // history.go(0);
  };

  if (isLoadingNoticeData || userInfoLoading) {
    return <FullLoading />;
  }

  const isLink =
    noticeData && noticeData.length > 0
      ? noticeData[0].url.startsWith('/')
      : false;

  return (
    <MoreContainer>
      <LoginContainer>
        {token ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {userInfo?.name ? (
              <AppLink
                to="/profile"
                title={'프로필'}
                style={{ display: 'flex' }}
              >
                {/* <UserLink> */}
                <span style={{ fontWeight: 'bold' }}>{userInfo?.name}</span>
                <span>님</span>
                <ArrowFront />
                {/* </UserLink> */}
              </AppLink>
            ) : (
              <UserLink to="/kyc">
                <span style={{ fontWeight: 'bold' }}>본인인증</span>
                <ArrowFront />
              </UserLink>
            )}
            <TokenButton onClick={() => setLogoutModal(true)}>
              로그아웃
            </TokenButton>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            로그인 해주세요
            <ButtonWrapper>
              <TokenLink color="#484F61" to="/signin">
                로그인
              </TokenLink>
              <SignUpTokenLink
                onClick={() => setIsSignUpAppOnlyModalOpen(true)}
                color="#FF8A22"
                style={{ marginLeft: 16 }}
              >
                회원가입
              </SignUpTokenLink>
              {isSignUpAppOnlyModalOpen && (
                <SignUpAppOnlyModal
                  isModalOpen={isSignUpAppOnlyModalOpen}
                  onModalClose={() => setIsSignUpAppOnlyModalOpen(false)}
                ></SignUpAppOnlyModal>
              )}
            </ButtonWrapper>
          </div>
        )}
      </LoginContainer>
      {noticeData && noticeData.length > 0 ? (
        <NoticeButton
          href={noticeData[0].url}
          rel={isLink ? '' : 'noopener noreferrer'}
          target={isLink ? '' : '_blank'}
        >
          <span>{noticeData[0].title}</span>
        </NoticeButton>
      ) : (
        <div></div>
      )}
      <MoreMiddleButtonWrapper>
        <MoreMiddleButtonLink to="/customer-service">
          <MoreQuestion />
          <span>고객센터</span>
        </MoreMiddleButtonLink>
        <MoreMiddleButtonLink to="/notice">
          <MoreNotice />
          <span>공지사항</span>
        </MoreMiddleButtonLink>
      </MoreMiddleButtonWrapper>
      <MoreRouteSectionNav
        style={{ borderBottom: '1px solid rgba(72, 79, 97, 0.1)' }}
      >
        <ul>
          <li>
            <CouponRegisterButton
              isAppOnlyModalOpen={isAppOnlyModalOpen}
              setIsAppOnlyModalOpen={setIsAppOnlyModalOpen}
            />
          </li>

          {/* <li>
            <SubscriptionButton
              isAppOnlyModalOpen={isAppOnlyModalOpen}
              setIsAppOnlyModalOpen={setIsAppOnlyModalOpen}
            />
          </li> */}

          <li>
            <BadgeListButton />
          </li>
        </ul>
      </MoreRouteSectionNav>

      <MoreRouteSectionNav
        style={{ borderBottom: '1px solid rgba(72, 79, 97, 0.1)' }}
      >
        <p>트레져러 이야기</p>
        <ul>
          <li>
            <MoreRouteSectionA
              href="https://notice.treasurer.co.kr/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <MoreBlog className="icon" />
              트레져러 블로그
            </MoreRouteSectionA>
          </li>
          <li>
            <MoreRouteSectionA
              href="https://notice.treasurer.co.kr/news"
              rel="noopener noreferrer"
              target="_blank"
            >
              <MoreNews className="icon" />
              트레져러 기사
            </MoreRouteSectionA>
          </li>
          <li>
            <MoreRouteSectionLink to="/about">
              <MoreLogo className="icon" />
              트레져러 소개
            </MoreRouteSectionLink>
          </li>
        </ul>
      </MoreRouteSectionNav>

      <MoreRouteSectionNav>
        <p>약관</p>
        <ul>
          <li>
            <MoreRouteSectionLink to="/privacy">
              <MorePrivacy className="icon" />
              트레져러 이용약관
            </MoreRouteSectionLink>
          </li>
        </ul>
      </MoreRouteSectionNav>

      {isRN ? (
        <ConfirmAppLinkModal
          modalOpen={logoutModal}
          title="로그아웃"
          content="로그아웃 하시겠습니까?"
          confirmBtnText="확인"
          confirmBtnAction={() => logout()}
          closeBtnText="취소"
          closeBtnAction={() => setLogoutModal(false)}
          onRequestClose={() => setLogoutModal(false)}
          to={'OnBoarding'}
          isLogout={true}
        />
      ) : (
        <ConfirmModal
          modalOpen={logoutModal}
          title="로그아웃"
          content="로그아웃 하시겠습니까?"
          confirmBtnText="확인"
          confirmBtnAction={() => logout()}
          closeBtnText="취소"
          closeBtnAction={() => setLogoutModal(false)}
          onRequestClose={() => setLogoutModal(false)}
        />
      )}
    </MoreContainer>
  );
};

export default More;
