import { ReactNode } from 'react';
import styled from 'styled-components';

export function AlarmSectionTitleBox({
  mainText,
  subText,
  padding,
}: {
  mainText: string;
  subText?: string;
  padding?: string;
}) {
  return (
    <TitleBox padding={padding}>
      <MainTitle>{mainText}</MainTitle>
      {subText && <SubTitle>{subText}</SubTitle>}
    </TitleBox>
  );
}

const SubTitle = styled.h2`
  color: #9e9e9e;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.42px;
`;

const MainTitle = styled.h1`
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
`;

const TitleBox = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: ${(props) => props.padding ?? '0 16px'};
`;

export function AlarmSectionWrapper({
  children: children,
  padding,
  isLastSection,
}: {
  children: ReactNode;
  padding?: string;
  isLastSection?: boolean;
}) {
  return (
    <Container isLastSection={isLastSection}>
      <Box padding={padding}>{children}</Box>
    </Container>
  );
}

const Box = styled.div<{ padding?: string }>`
  padding: ${(props) => props.padding ?? '20px 0'};
`;

const Container = styled.section<{ isLastSection?: boolean }>`
  width: 100%;
  max-width: 1000px;
  border-bottom: ${(props) => !props.isLastSection && '6px solid #f5f5f5'};
`;
