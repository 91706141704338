import { useCreateCryptoApiKey } from '@/Queries/useCreateCryptoApiKey';
import { isNil } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function CoinoneRedirectPage() {
  const parsedResult = queryString.parse(window.location.search);
  const history = useHistory();
  const { mutate: requestAccessToken, isLoading } = useCreateCryptoApiKey();
  const [referer, setReferer] = useState(
    localStorage.getItem('coinoneReferer'),
  );

  useEffect(() => {
    const isRN = (window as any).isRNWebView || false;
    const isHomeV2 = (window as any).homeV2 || false;
    if (isRN) {
      // if react native webview, open CoinoneCallbackScreen
      // Navite Screen will request access token
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          to: 'CoinoneCallbackScreen',
          type: 'APPLINK_EVENT',
          isHomeV2,
          referer,
          requestToken: parsedResult.request_token,
        }),
      );
    } else {
      // if web, request access token
      history.replace('/my');
      if (
        !isNil(parsedResult.request_token) &&
        typeof parsedResult.request_token === 'string'
      ) {
        requestAccessToken({
          request_token: parsedResult.request_token,
        });
      }
    }
  }, []);

  return (
    <div>
      {isLoading && (
        <div>
          <p>요청 진행중입니다.</p>
        </div>
      )}
    </div>
  );
}
