import {
  BenefitsEventsSection,
  InterestSection,
  VoteSection,
} from '@/Components/Alarm';
import Loading from '@/Components/Loading';
import {
  NotificationTopic,
  PostUserNotificationSettingsRequestType,
} from '@/fetcher/alarmFetchers';
import {
  useGetUserNotificationSettings,
  usePostUserNotificationToggle,
} from '@/fetcher/alarmQueries';
import { useCallback } from 'react';
import styled from 'styled-components';

export interface AlarmSectionProps {
  onToggleSwitch: (params: {
    type: PostUserNotificationSettingsRequestType;
    name?: string;
    optIn: boolean;
  }) => void;
  getTopicOptIn?: (
    name: string,
    topics: NotificationTopic[],
  ) => number | undefined;
}

export default function Alarm() {
  const deviceId = localStorage.getItem('device') || '';
  const { isLoading } = useGetUserNotificationSettings(deviceId);

  const { mutate: postUserNotificationToggle } =
    usePostUserNotificationToggle();

  const onToggleSwitch = useCallback(
    async ({
      type,
      name,
      optIn,
    }: {
      type: PostUserNotificationSettingsRequestType;
      name?: string;
      optIn: boolean;
    }) => {
      if (isLoading) return null;
      postUserNotificationToggle({ type, name, optIn });
    },
    [isLoading],
  );

  const getTopicOptIn = (name: string, topics: NotificationTopic[]) =>
    topics?.find((topic) => topic.name === name)?.optIn;

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BenefitsEventsSection onToggleSwitch={onToggleSwitch} />
          {/* topic 알림은 앱에서만 가능 */}
          {/* <InterestSection onToggleSwitch={onToggleSwitch} getTopicOptIn={getTopicOptIn}/>
          <VoteSection
            onToggleSwitch={onToggleSwitch}
            getTopicOptIn={getTopicOptIn}
          /> */}
        </>
      )}
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
`;
