import { StyledSwitch } from '@/Components/ui/StyledSwitch';
import { useCallback } from 'react';
import styled from 'styled-components';

interface AlarmSwitchProps {
  value: boolean;
  onPress: () => void;
  text: string;
}

export default function AlarmSwitch({
  value,
  onPress,
  text,
}: AlarmSwitchProps) {
  const toggleSwitch = useCallback(() => {
    onPress();
  }, [onPress]);

  return (
    <Container>
      <Warper>
        <Label>{text}</Label>
        <StyledSwitch
          defaultChecked={value}
          value={value}
          onClick={toggleSwitch}
        />
      </Warper>
    </Container>
  );
}

const Label = styled.p`
  color: #000;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Warper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
`;

const Container = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;
