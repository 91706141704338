import { useGetUserNotificationSettings } from '@/fetcher/alarmQueries';
import { AlarmSectionProps } from '@/Routes/Alarm';
import styled from 'styled-components';
import { AlarmSectionTitleBox, AlarmSectionWrapper } from './AlarmStyles';
import AlarmSwitch from './AlarmSwitch';

export default function BenefitsEventsSection({
  onToggleSwitch,
}: AlarmSectionProps) {
  const deviceId = localStorage.getItem('device') || '';
  const { data } = useGetUserNotificationSettings(deviceId);

  return (
    <AlarmSectionWrapper>
      <Container>
        <AlarmSectionTitleBox
          mainText="혜택 및 이벤트 알림"
          subText="할인, 쿠폰 등의 이벤트 정보를 가장 먼저 알려드려요"
        />
        <div>
          <AlarmSwitch
            onPress={() =>
              onToggleSwitch({
                type: 'channel',
                name: 'sms',
                optIn: !data?.sms,
              })
            }
            text="문자 알림"
            value={data?.sms === 1}
          />
          <AlarmSwitch
            onPress={() =>
              onToggleSwitch({
                type: 'channel',
                name: 'kakao',
                optIn: !data?.kakao,
              })
            }
            text="알림톡 알림"
            value={data?.kakao === 1}
          />
          <AlarmSwitch
            onPress={() =>
              onToggleSwitch({
                type: 'channel',
                name: 'push',
                optIn: !data?.push,
              })
            }
            text="푸시 알림"
            value={data?.push === 1}
          />
          <AlarmSwitch
            onPress={() =>
              onToggleSwitch({ type: 'night', optIn: !data?.night })
            }
            text="야간 푸시 알림(21 ~ 08시)"
            value={data?.night === 1}
          />
        </div>
      </Container>
    </AlarmSectionWrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
