import client from '@/apis/axios-instance';
import { BaseResponse } from '@treasurer/common';
import { useQuery } from 'react-query';
type CoinoneUserAllAssetResponse = {
  result: string;
  error_code: string;
  balances: CoinoneUserAssetItem[];
};

type CoinoneUserAssetItem = {
  currency: string;
  available: string;
  limit: string;
  average_price: string;
};

export const useUserCryptoAsset = () =>
  useQuery('userCryptoAsset', async () => {
    const { data } = await client.get<
      BaseResponse<CoinoneUserAllAssetResponse>
    >('/v2/crypto/userAsset');

    return data.data!;
  });
