import { PurchaseProvider } from '@/contexts/PurchaseContext';
import SubscriptionLandingPage from '@/pages/LandingPages/SubscriptionLandingPage';
import HomeMarketPreviewPage from '@/pages/MarketNew/HomeMarketPreviewPage';
import PointUseHistoryPage from '@/pages/My/PointUseHistoryPage';
import PaymentPage from '@/pages/Payment/PaymentPage';
import PaymentPageTest from '@/pages/Payment/PaymentPageTest';
import LiquidatedItemsWebView from '@/Routes/LiquidatedItemsWebView';
import ProposalVoteResult from '@/Routes/Vote/ProposalVoteResult';
import { useMediaQuery } from '@material-ui/core';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, useLocation } from 'react-router-dom';
import CustomerService from '../Routes/CustomerService';
import Delete from '../Routes/Delete';
import EmailConfirm from '../Routes/EmailConfirm';
import More from '../Routes/More';
import NoticePage from '../Routes/NoticePage';
import RedirectPage from '../Routes/RedirectPage';
import Upcoming from '../Routes/Upcoming';
import ScrollToTop from '../utils/ScrollRestoration';
import CoinoneRedirectPage from './Crypto/CoinoneRedirectPage';
import CryptoAssetPage from './Crypto/CryptoAssetPage';
import CryptoPage from './Crypto/CryptoPage';
import Footer from './Footer';
import Header from './Header';
import MobileBottomNavi from './MobileBottomNavi';
import LinkAuthVirtualAccount from './VirtualAccountLinked/LinkAuthVirtualAccount';
import DefaultEventLandingPage from '@/pages/EventLanding/DefaultEventLandingPage';
import BadgeEventLanding from '@/pages/EventLanding/BadgeEventLanding';
import Alarm from '@/Routes/Alarm';

const Home = lazy(() => import('../Routes/Home'));
const ItemDetail = lazy(() => import('../Routes/ItemDetail'));
const Piece = lazy(() => import('../Routes/Piece'));
const Disposal = lazy(() => import('../Routes/Disposal'));
// const Market = lazy(() => import('../Routes/Market'));
const Market = lazy(() => import('../pages/Market')); // 개발 보류
// 위 코드 market페이지 개발 시 적용
const About = lazy(() => import('../Routes/About'));
const Watch = lazy(() => import('../Routes/Watch'));
const SignUp = lazy(() => import('../Routes/Sign/Signup'));
// const SignUp = lazy(() => import('../Routes/SignUp'));
const Sell = lazy(() => import('../Routes/Sell'));
const SignIn = lazy(() => import('../Routes/Sign/Signin'));
const UserKyc = lazy(() => import('../Routes/Sign/UserKyc'));
const ResetPassword = lazy(() => import('../Routes/password/ResetPassword'));
const NewPassword = lazy(() => import('../Routes/password/NewPassword'));
const Vote = lazy(() => import('../Routes/Vote'));
const Badge = lazy(() => import('../Routes/Badge'));
const SaleVoteDetail = lazy(() => import('../Routes/Vote/SaleVoteDetail'));
const PriceVoteDetail = lazy(() => import('../Routes/Vote/PriceVoteDetail'));
const NoticeDetail = lazy(() => import('../Routes/NoticeDetail'));
const Club = lazy(() => import('../Routes/Club'));
const Benefit = lazy(() => import('@/Routes/Benefit'));
const TradeItemDetail = lazy(() => import('@/pages/TradeItem/TradeItemDetail'));
const VirtualAccountWithdrawPage = lazy(() =>
  import('./VirtualAccount/VirtualAccountWithdrawPage'),
);
const Privacy = lazy(() => import('@/Routes/Privacy'));
const NewMyPage = lazy(() => import('./NewMyPage/NewMyPage'));
const Contract = lazy(() => import('@/pages/Contract'));
const MarketPage = lazy(() => import('@/pages/MarketNew/MarketPage'));
const NotFound = lazy(() => import('../Routes/NotFound'));
const PortfolioCurationPage = lazy(() =>
  import('../pages/PortfolioCuration/PortfolioCurationPage'),
);
const Profile = lazy(() => import('@/Routes/My/profile'));
const PushSetting = lazy(() => import('@/Routes/My/profile/PushSetting'));
const CopyTradingOrderPage = lazy(() =>
  import('../pages/PortfolioCuration/CopyTradingOrderPage'),
);
const MyMoneyTransactionHistory = lazy(() =>
  import('./NewMyPage/MyMoneyTransactionHistory'),
);

const MyNotificationPage = lazy(() => import('./NewMyPage/MyNotificationPage'));
const VoteSession = lazy(() => import('@/Routes/VoteSession'));

ReactGA.initialize('UA-188316786-2');

const PATHNAMES_WITHOUT_FOOTER = [
  '/event/',
  '/portfolio-curation',
  '/copy-trading',
  '/landing',
  '/market-preview',
  '/virtual-account-deposit',
  '/soldout-webview',
  '/payment',
  '/test-payment',
];

const AppRouter = () => {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const isHomeV2 = window.homeV2 || false;

  const [isFooterHidden, setIsFooterHidden] = useState(false);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);

    const isPageWithoutFooter = PATHNAMES_WITHOUT_FOOTER.some((string) =>
      location.pathname.includes(string),
    );

    setIsFooterHidden(isPageWithoutFooter);
  }, [location]);

  const scrollTop = useMemo(() => {
    if (location.pathname !== '/market') return true;
    if (location.pathname.split('/')[1] !== 'market') return true;
    if (location.pathname.split('/').length > 1)
      if (!Number.isNaN(location.pathname.split('/')[2])) return true;
    return false;
  }, []);

  return (
    <>
      <Header />
      {scrollTop && <ScrollToTop />}
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/coinone" exact component={CoinoneRedirectPage} />
          <Route path="/sell" exact component={Sell} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/kyc" component={UserKyc} />
          <Route path="/password-reset" component={ResetPassword} />
          <Route path="/watch" component={Watch} />
          <Route path="/crypto-copy" component={CryptoPage} />
          <Route path="/market" component={MarketPage} />
          <Route path="/market-preview" component={HomeMarketPreviewPage} />
          <Route path="/new-password" component={NewPassword} />
          <Route path="/email-confirm" component={EmailConfirm} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/upcoming" component={Upcoming} />
          <Route path="/piece" component={Piece} />
          <Route path="/soldout" component={Disposal} />
          <Route path="/soldout-webview" component={LiquidatedItemsWebView} />
          <Route path="/more" component={More} />
          <Route path="/about" component={About} />
          <Route path="/redirect" component={RedirectPage} />
          <Route path="/delete" component={Delete} />
          <Route path="/alarm" component={Alarm} />
          <Route path="/customer-service" component={CustomerService} />
          <Route exact path="/notice" component={NoticePage} />
          <Route exact path="/vote" component={Vote} />
          <Route exact path="/vote-sale/:voteId" component={SaleVoteDetail} />
          <Route
            exact
            path="/vote-price/:productId"
            component={PriceVoteDetail}
          />
          <Route
            exact
            path="/vote-price-result/:proposalId"
            component={ProposalVoteResult}
          />
          <Route exact path="/vote-session/:uuid" component={VoteSession} />
          <Route exact path="/notice/:title" component={NoticeDetail} />
          <Route exact path="/contract/:itemId" component={Contract} />
          <Route exact path="/badge" component={Badge} />
          <Route
            exact
            path="/withdraw"
            component={VirtualAccountWithdrawPage}
          />
          <Route exact path="/my" component={NewMyPage} />
          <Route
            exact
            path="/payment-history/points"
            component={PointUseHistoryPage}
          />
          <Route exact path="/notification" component={PushSetting} />
          <Route exact path="/my-notification" component={MyNotificationPage} />
          <Route
            exact
            path="/transactions"
            component={MyMoneyTransactionHistory}
          />
          <Route exact path="/profile" component={Profile} />
          <Route
            exact
            path="/portfolio-curation"
            component={PortfolioCurationPage}
          />
          <Route exact path="/copy-trading" component={CopyTradingOrderPage} />
          <Route
            exact
            path="/virtual-account-deposit/:uuid"
            component={LinkAuthVirtualAccount}
          />
          {/* <Route
            exact
            path="/portfolio/:userId"
            component={MyPortfolioProfile}
          /> */}
          <Route exact path="/benefit" component={Benefit} />
          <Route exact path="/crypto-asset" component={CryptoAssetPage} />

          <Route exact path="/event" component={DefaultEventLandingPage} />
          <Route exact path="/event-badge" component={BadgeEventLanding} />
          <Route
            exact
            path="/event/subscription"
            component={SubscriptionLandingPage}
          />

          <PurchaseProvider>
            <Route path="/item/:id" component={TradeItemDetail} />
            <Route path="/item/:id/admin" component={TradeItemDetail} />
            <Route path="/item/:id/test" component={TradeItemDetail} />
            <Route path="/payment/:tradeItemId" component={PaymentPage} />
            <Route
              path="/test-payment/:tradeItemId"
              component={PaymentPageTest}
            />
          </PurchaseProvider>

          <Route component={NotFound} />
        </Switch>
        {!isFooterHidden && <Footer />}
        {isMobile && !isHomeV2 && <MobileBottomNavi />}
      </Suspense>
    </>
  );
};

export default AppRouter;
