import styled from 'styled-components';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  VoteContainer,
  VoteDate,
  DisplayColumn,
  MiddleGrayTxt,
  VoteCriteria,
  VoteRate,
  LargeBoldTxt,
  VoteProgress,
  SmallGrayTxt,
  VoteRefresh,
  PointText,
  ProductIdText,
} from './VoteStyle';
import { SpaceBetween } from '../../config/style';
import {
  checkExistingUserVote,
  getVoteItemByProposalId,
} from '@/fetcher/voteQueries';
import AppLink from '@/Components/AppLink';
import { RouteComponentProps } from 'react-router-dom';
import { ReactComponent as ActionCompleteImage } from '../../assets/images/actionComplete.svg';
import { ReactComponent as VoteCheck } from '../../assets/images/check_vote.svg';
import { FlexDiv } from '@/Components/Home/Mobile/HomeMobileNotice/HomeMobileNoticeStyle';
import { ReactComponent as Refresh } from '@/assets/images/Refresh.svg';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { PriceVoteNotices } from '@/Components/Vote';
import { useMediaQuery } from '@material-ui/core';
import ReactHelmet from '@/Components/ReactHelmet';

interface MatchParams {
  proposalId: number;
}

const metaData = {
  title: '투표 - 트레져러',
  description:
    '구매 희망자가 나타나면 조각 소유주들의 투표로 매각 여부를 결정해요.',
  image:
    'https://treasurer-source.s3.ap-northeast-2.amazonaws.com/trade-item-image/og_image.png',
  canonical: `/vote`,
};

function ProposalVoteResult({ match }: RouteComponentProps<MatchParams>) {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const history = useHistory();
  const queryClient = useQueryClient();
  const [refreshDelay, setRefreshDelay] = useState(false);

  const { proposalId } = match.params;

  const {
    data: voteItemData,
    isLoading: isVoteItemDataLoading,
    isRefetching,
  } = getVoteItemByProposalId(proposalId);
  const { data: existingUserVote, isLoading: isCheckExistingUserVoteLoading } =
    checkExistingUserVote(proposalId);

  if (isVoteItemDataLoading || isCheckExistingUserVoteLoading) return null;

  const voteData = voteItemData.voteData;
  const itemData = voteItemData.itemData;
  const voteAllPiece = itemData.totalPieceCount;
  const turnout = voteData.yes + voteData.no;
  const voteRate = (turnout / voteAllPiece) * 100;
  const tradeItemId = voteData.tradeItemId;

  const isVoteExpired =
    voteData.voteCategory === 'PROPOSAL_ACCEPTED' ||
    voteData.voteCategory === 'PROPOSAL_REJECTED';

  const chartState = {
    series: [turnout, voteAllPiece - turnout],
    options: {
      chart: {
        type: 'donut' as 'donut',
        witdh: '106px',
        height: '106px',
      },
      fill: {
        colors: ['#FFA41B', 'rgba(255, 164, 27, 0.5)'],
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      events: {
        click: undefined,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        normal: {
          filter: {
            type: 'none',
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
      },
    },
  };

  const handleRefresh = () => {
    setRefreshDelay(true);
    setTimeout(() => {
      setRefreshDelay(false);
      queryClient.invalidateQueries('getVoteItemByProposalId');
    }, 1000);
  };

  const isRN = (window as any).isRNWebView || false;

  const handleClickProductInfo = (tradeItemId: number) => {
    history.push(`/item/${tradeItemId}`);
  };

  return (
    <Wrapper>
      <ReactHelmet data={metaData} />
      <VoteContainer>
        <ActionCompleteTitleSection>
          {!isVoteExpired && !existingUserVote?.alreadyVoted && isRN && (
            <PointText>+3P</PointText>
          )}

          <ActionCompleteImage
            style={{ display: 'block', margin: '0 auto', width: '90px' }}
          />

          {isVoteExpired ? (
            <h1>
              투표기간이 지나
              <br />
              매각 희망가 투표가
              <br />
              종료되었습니다.
            </h1>
          ) : (
            <h1>
              매각 희망가 투표가
              <br />
              완료되었습니다.
            </h1>
          )}
        </ActionCompleteTitleSection>

        <SpaceBetween
          style={{ marginBottom: '16px', cursor: 'pointer' }}
          onClick={() => handleClickProductInfo(tradeItemId)}
        >
          <NameBox>
            <h1>{itemData.brand}</h1>
            <h2>{itemData.model}</h2>
            <h3>
              {itemData.brandKR} {itemData.modelKR}
            </h3>
          </NameBox>
          <ThumbnailImage src={itemData.thumbnail} />
        </SpaceBetween>

        <VoteDate>
          투표기간: {moment(voteData.endDate).format('YYYY-MM-DD HH:mm')} 까지
        </VoteDate>

        <DisplayColumn style={{ marginTop: 40 }}>
          <div id="chart">
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="donut"
              width="140px"
              height="140px"
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <VoteCriteria>
              투표율 {moment(voteData.updatedAt).format('YYYY-MM-DD HH:mm')}{' '}
              기준
            </VoteCriteria>
            <VoteRefresh type="button" onClick={handleRefresh}>
              <Refresh
                className={isRefetching || refreshDelay ? 'refresh' : undefined}
              />
            </VoteRefresh>
          </div>

          <VoteRate>
            {voteRate.toFixed(2)}
            <span>%</span>
          </VoteRate>
        </DisplayColumn>

        <div style={{ marginTop: 32, display: 'flex' }}>
          <DisplayColumn
            style={{
              borderRight: '1px solid rgba(72, 79, 97, 0.4)',
              width: '100%',
            }}
          >
            <SpaceBetween
              style={{
                width: '100%',
                paddingRight: '16px',
                textAlign: 'left',
              }}
            >
              <MiddleGrayTxt>유권자</MiddleGrayTxt>
              <span>{voteData.ownerCnt.toLocaleString()} 명</span>
            </SpaceBetween>
            <SpaceBetween
              style={{
                width: '100%',
                paddingRight: '16px',
                marginTop: 25,
                textAlign: 'left',
              }}
            >
              <MiddleGrayTxt style={{ marginRight: 10 }}>투표수</MiddleGrayTxt>
              <span>{voteData.voteCnt.toLocaleString()} 명</span>
            </SpaceBetween>
          </DisplayColumn>
          <DisplayColumn
            style={{
              width: '100%',
              marginLeft: 16,
            }}
          >
            <SpaceBetween
              style={{
                width: '100%',
                textAlign: 'left',
              }}
            >
              <MiddleGrayTxt>전체소유권</MiddleGrayTxt>
              <span>{voteAllPiece.toLocaleString()} 개</span>
            </SpaceBetween>
            <SpaceBetween
              style={{
                width: '100%',
                marginTop: 25,
                textAlign: 'left',
              }}
            >
              <MiddleGrayTxt style={{ marginRight: 10 }}>
                투표소유권
              </MiddleGrayTxt>
              <span>{turnout.toLocaleString()} 개</span>
            </SpaceBetween>
          </DisplayColumn>
        </div>
        <div style={{ marginTop: 30 }}>
          <SpaceBetween>
            <LargeBoldTxt>
              {isNaN(voteData.no / turnout)
                ? '0%'
                : `${((voteData.no / turnout) * 100).toFixed(2)}%`}
            </LargeBoldTxt>
            <LargeBoldTxt>
              {isNaN(voteData.yes / turnout)
                ? '0%'
                : `${((voteData.yes / turnout) * 100).toFixed(2)}%`}
            </LargeBoldTxt>
          </SpaceBetween>
          <SpaceBetween style={{ marginTop: 8 }}>
            <FlexDiv>
              <MiddleGrayTxt>반대</MiddleGrayTxt>
              {existingUserVote?.voteOption === 2 && (
                <VoteCheck style={{ marginRight: '4px' }} />
              )}
            </FlexDiv>
            <FlexDiv>
              {existingUserVote?.voteOption === 1 && (
                <VoteCheck style={{ marginRight: '4px' }} />
              )}
              <MiddleGrayTxt>찬성</MiddleGrayTxt>
            </FlexDiv>
          </SpaceBetween>
          <div style={{ marginTop: 8 }}>
            {turnout === 0 ? (
              <VoteProgress
                style={{ width: '100%', background: 'rgba(72, 79, 97, 0.1)' }}
              />
            ) : (
              <div style={{ display: 'flex' }}>
                <VoteProgress
                  style={{
                    width: `${(voteData.no / turnout) * 100}%`,
                    background: '#213E85',
                    marginRight: 1,
                    borderRadius: '4px 0px 0px 4px',
                  }}
                />
                <VoteProgress
                  style={{
                    width: `${(voteData.yes / turnout) * 100}%`,
                    background: '#FF8A22',
                  }}
                />
              </div>
            )}
          </div>
          <SpaceBetween
            style={{
              marginTop: 8,
            }}
          >
            <SmallGrayTxt>{voteData.no.toLocaleString()}개</SmallGrayTxt>
            <SmallGrayTxt>
              개수 차 {Math.abs(voteData.yes - voteData.no).toLocaleString()}
            </SmallGrayTxt>
            <SmallGrayTxt>{voteData.yes.toLocaleString()}개</SmallGrayTxt>
          </SpaceBetween>
        </div>

        <PriceVoteNotices />

        {!isMobile && <ProductIdText>{voteData.tradeItemId}</ProductIdText>}

        <AppLink to={'/vote'} title="투표">
          <FloatingButton>확인</FloatingButton>
        </AppLink>
      </VoteContainer>
    </Wrapper>
  );
}

export default ProposalVoteResult;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60px;
  position: relative;
`;

const ActionCompleteTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #060a10;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 40px;
  }
`;

const FloatingButton = styled.div`
  background: #fe8824;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  padding: 17px;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 92%;
  max-width: 490px;
  cursor: pointer;
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h1 {
    color: #000;
    font-family: SUIT;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    color: #000;
    font-family: SUIT;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  h3 {
    color: #a6a6a6;
    font-family: SUIT;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
`;

const ThumbnailImage = styled.div<{ src: string }>`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-color: #fff;
`;
