import Switch from '@mui/material/Switch';
import { styled as muiStyled } from '@mui/material/styles';

export const StyledSwitch = muiStyled(Switch)({
  width: 52,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(25px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FF8A22',
        opacity: 1,
      },
    },
    '& + .MuiSwitch-track': {
      borderRadius: 50,
    },
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
  },
});
