import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getUserNotificationSettings,
  PostUserNotificationSettingsRequestType,
  postUserNotificationToggle,
} from './alarmFetchers';

export const useGetUserNotificationSettings = (deviceId: string) => {
  return useQuery({
    queryKey: ['getUserNotificationSettings', deviceId],
    queryFn: () => getUserNotificationSettings(deviceId),
    enabled: !!deviceId,
  });
};

export const usePostUserNotificationToggle = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'postUserNotificationToggle',
    async ({
      type,
      name,
      optIn,
    }: {
      type: PostUserNotificationSettingsRequestType;
      name?: string;
      optIn: boolean;
    }) => {
      return await postUserNotificationToggle({ type, name, optIn });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['getUserNotificationSettings']);
      },
    },
  );
};
