import { ApiResult } from '@/types/Home/Types';
import axios from '../apis/axios-instance';

type NotificationTopicTitle =
  | '매각 투표'
  | '매각 희망가'
  | '출석 체크'
  | '행운 상자'
  | '조각 구매';
export type NotificationTopicName =
  | 'DISPOSAL_VOTE'
  | 'PROPOSAL_VOTE'
  | 'DAILY_CHECK'
  | 'LUCKYBOX'
  | 'BUY_PIECE'
  | 'BUY_PIECE';
export interface NotificationTopic {
  name: NotificationTopicName;
  optIn: number;
  title: NotificationTopicTitle;
}
export interface UserNotificationSettingsResponse {
  deviceOptIn: 0 | 1;
  email: 0 | 1;
  kakao: 0 | 1;
  marketing: 0 | 1;
  night: 0 | 1;
  push: 0 | 1;
  sms: 0 | 1;
  topics: NotificationTopic[];
}

export const getUserNotificationSettings = async (deviceId: string) => {
  const { data } = await axios.get<ApiResult<UserNotificationSettingsResponse>>(
    `/v2/user/notification/settings?deviceId=${deviceId}`,
  );

  return data.data;
};

export type PostUserNotificationSettingsRequestType =
  | 'channel'
  | 'topic'
  | 'night'
  | 'marketing';
export interface PostUserNotificationSettingsRequest {
  type: PostUserNotificationSettingsRequestType;
  name?: string;
  optIn: boolean;
}
export const postUserNotificationToggle = async ({
  type,
  name,
  optIn,
}: PostUserNotificationSettingsRequest) => {
  try {
    const { data } = await axios.post(`/v2/user/notification/toggle`, {
      type,
      name,
      optIn,
    });
    return data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};
