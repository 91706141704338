import { SpaceBetween } from '@/styles/CommonStyledComponents';
import styled from 'styled-components';
import Loading from '../Loading';

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FullPageLoading = () => {
  return (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  );
};

export const SectionHeader = styled(SpaceBetween)`
  padding: 24px 0;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

export const SectionContainer = styled.div`
  padding: 24px 16px;
`;

export const SummarySection = styled(SectionContainer)`
  background: #f6f6f7;
`;

export const SummaryContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 16px;
`;

