import { useUserCryptoAsset } from '@/Queries/useUserCryptoAsset';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { FullPageLoading } from '../NewMyPage/MyPageStyles';

export default function CryptoAssetPage() {
  const parsedResult = queryString.parse(window.location.search);
  const { data: userCryptoAsset, isLoading } = useUserCryptoAsset();
  const history = useHistory();
  if (!userCryptoAsset || isLoading) {
    return <FullPageLoading />;
  }

  return (
    <div>
      {userCryptoAsset.balances.map((balance) => (
        <div key={balance.currency} style={{ display: 'flex' }}>
          <p>{balance.currency}</p>
          <p>{balance.available}</p>
        </div>
      ))}
    </div>
  );
}
