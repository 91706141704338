import { useGetUserNotificationSettings } from '@/fetcher/alarmQueries';
import { AlarmSectionProps } from '@/Routes/Alarm';
import styled from 'styled-components';
import { AlarmSectionTitleBox, AlarmSectionWrapper } from './AlarmStyles';
import AlarmSwitch from './AlarmSwitch';

const interestTopicNameList = ['DAILY_CHECK', 'LUCKYBOX', 'BUY_PIECE'];

export default function InterestSection({
  onToggleSwitch,
  getTopicOptIn,
}: AlarmSectionProps) {
  const deviceId = localStorage.getItem('device') || '';
  const { data } = useGetUserNotificationSettings(deviceId);
  const interestTopics = data?.topics.filter((topic) =>
    interestTopicNameList.includes(topic.name),
  );

  return (
    <AlarmSectionWrapper>
      <Container>
        <AlarmSectionTitleBox
          mainText="관심 기능 알림"
          subText="알림 신청한 기능의 소식을 보내드려요"
        />
        {interestTopics?.map((topic) => {
          return (
            <AlarmSwitch
              key={topic.name}
              onPress={() =>
                onToggleSwitch({
                  type: 'topic',
                  name: topic.name,
                  optIn: !getTopicOptIn!(topic.name, interestTopics),
                })
              }
              text={topic.title}
              value={getTopicOptIn!(topic.name, interestTopics) === 1}
            />
          );
        })}
      </Container>
    </AlarmSectionWrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
